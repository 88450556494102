/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./theme.scss";
//mat table responsive css
@import './app/material/mat-table-responsive/mat-table-responsive.directive.scss';  


html,
body {
    height: 100%;
    color: #212529;
}

.button-dialog {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px !important;
}

.mat-toolbar {
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    background: transparent;
}

.mat-toolbar {
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    background: transparent;
}

 .units-picker-component .mat-form-field-infix {
    display: inherit;
}
 
.fields-note {
    font-style: italic;
}

.notes-style {
    font-weight: bold;
    color: indianred;
}

.cdk-overlay-pane.my-dialog {
    position: relative !important;
}

.close.mat-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    // line-height: 14px;
    min-width: auto;
}

fieldset {
    border: 2px solid #01465f;
    padding: 1em;
    float: left;
    font-family: Arial;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 4px;
}

legend {
    display: none;
}

h2 {
    //border-bottom: 2px solid gray;
    margin: 1em 0;
}

p {
    margin: 1em 0;
}

::ng-deep .mat-radio-label-content {
    white-space: break-spaces !important;
}

.calculator-tools {
    padding: 20px;
   //  background: #c5e9e7;
    background: linear-gradient(221deg, rgba(0, 156, 147, .23), rgba(13, 97, 124, .23));
    background-image: linear-gradient(221deg, rgba(0, 156, 147, 0.23), rgba(13, 97, 124, 0.23));
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}



.tools-header {
    padding: 0.3rem 0 2rem;
    .top-logo {
        //display: flex;
        justify-content: space-between;
        padding: 0 0 0 5rem;
        .row-logo {
            display: flex;
            justify-content: flex-end;
            padding-bottom: .5rem;
            img {
                padding: 0 .75rem;
            }
        }
    }
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 0 0 100%;
    font-size: 48px;
    h2 {
        font-size: 35px;
        line-height: 58px;
        font-weight: 900;
        margin-bottom: unset;
        text-transform: uppercase;
    }
    p {
        font-size: 30px;
        margin-bottom: 0;
        line-height: 34px;
    }
}

@media only screen and (max-width: 600px) {
     ::ng-deep .mat-horizontal-stepper-header-container {
        display: none !important;
    }
    .title-container {
        h2 {
            font-size: 20px;
            line-height: 24px;
            font-weight: 900;
            margin-bottom: unset;
            text-transform: uppercase;
        }
        p {
            font-size: 14px;
            margin-bottom: 0;
            line-height: 20px;
        }
    }
}

.row_inline {
    display: flex;
    margin: 0;
}

 ::ng-deep .mat-form-field-appearance-outline.mat-form-field-should-float {
    .mat-form-field-label {
        transform: none;
        width: initial;
        opacity: 0;
    }
    .mat-form-field-outline-gap {
        border-top-color: initial !important;
    }
} 


::ng-deep  .mat-form-field-appearance-fill .mat-form-field-flex {
    background:white;
  }


  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.question-text{
    font-weight: bold;
}
[data-tip] {
	position:relative;

}
[data-tip]:before {
	content:'';
	/* hides the tooltip when not hovered */
	display:none;
	content:'';
	display:none;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #1a1a1a;
	position:absolute;
	top:30px;
	left:35px;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
	position:absolute;
	top:30px;
	left:35px;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
}
[data-tip]:after {
	display:none;
	content:attr(data-tip);
	position:absolute;
	top:35px;
	left:0px;
	padding:5px 8px;
	background:#1a1a1a;
	color:#fff;
	z-index:9;
	font-size: 0.75em;
	height:18px;
	line-height:18px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	white-space:nowrap;
	word-wrap:normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display:block;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.75em 0 0.75em;
    margin-top: -0.25em;
    position: relative;
}



.field-group{
    display: flex;
    flex-direction: column;
  }
  
  
  .field-align{
    display: flex;
  }

  ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
}

::ng-deep .table thead th {
    text-align: center;
}


::ng-deep .mat-form-field-infix{
    font-style:italic ;  
  }


  